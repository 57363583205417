<template>
  <div class="article">
    <div class="contents">
      <div class="order_info_list" v-if="target_lot != null">
        <div>
          <div class="input_text">
            <label>상태</label>
            <input
              type="text"
              placeholder=""
              readonly
              :value="
                findInfoFromId(lot_types, this.target_lot.lot_type_id).name
              "
            />
          </div>
          <div class="input_text">
            <label>생산제품</label>
            <input
              type="text"
              placeholder="생산제품"
              readonly
              :value="findInfoFromId(products, target_lot.product_id).name"
            />
          </div>
          <button
            @click="goBomManagementPage(target_lot.product_id)"
            v-if="target_lot.product_id != null"
            class="btn_sub2"
          >
            BOM
          </button>
        </div>
        <div>
          <div class="input_text">
            <label>완료일정</label>
            <input
              type="text"
              placeholder="YYYY-MM-DD"
              readonly
              :value="target_lot.deadline_date"
            />
          </div>
        </div>
        <div>
          <div class="input_text">
            <label>납품처</label>
            <input
              type="text"
              placeholder="납품처"
              readonly
              :value="findInfoFromId(companys, target_lot.company_id).name"
            />
          </div>
        </div>
        <div>
          <div class="input_text">
            <label
              >작업수량({{
                findInfoFromId(
                  units,
                  findInfoFromId(products, target_lot.product_id).stock_unit_id,
                ).name
              }})</label
            >
            <input
              type="text"
              placeholder="작업수량"
              readonly
              :value="
                `${$makeComma(target_lot.quantity)}${
                  findInfoFromId(
                    units,
                    findInfoFromId(products, target_lot.product_id)
                      .stock_unit_id,
                  ).name
                }`
              "
            />
          </div>
        </div>
        <div>
          <div class="input_text">
            <label>LOT번호</label>
            <input
              type="text"
              placeholder="LOT번호"
              readonly
              :value="
                findInfoFromId(lot_types, target_lot.lot_type_id).detail !=
                'wait process'
                  ? target_lot.lot_number
                  : ''
              "
            />
          </div>
          <button
            v-if="
              findInfoFromId(lot_types, target_lot.lot_type_id).detail !=
                'wait process'
            "
            class="btn_sub2"
            @click="trackingLotNum(target_lot.lot_number)"
          >
            추적
          </button>
        </div>
        <div>
          <button
            class="btn_sub1"
            @click="doneProcess"
            v-if="
              target_lot.lot_type_id ==
                lot_types.find(x => x.detail == 'processing').id &&
                validChk &&
                isPermissionOn
            "
          >
            작업 완료처리
          </button>
        </div>
      </div>
      <div
        class="progress_contents"
        :class="{
          card_5:
            target_lot != null
              ? target_lot.bom_info.bom_process.length - 1 <= 5
                ? true
                : false
              : false,
        }"
      >
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <component
              :is="dynamicComponent"
              v-for="(lot_process, index) in target_lot != null
                ? target_lot.bom_info.bom_process
                : []"
              :key="lot_process.id + keyCountForReDraw"
              :process_id="lot_process.process_id"
              :process_name="
                findInfoFromId(process, lot_process.process_id).name
              "
              :sub="lot_process.index"
              :id="`chartData${lot_process.id}`"
              :is_last_process="
                index == target_lot.bom_info.bom_process.length - 1
              "
              :lot_quantity="target_lot.quantity"
              :wait_quantity="getWaitQuantity(lot_process)"
              :production_info="lot_process.production_info"
              :lot_id="target_lot.id"
              :product_id="target_lot.product_id"
              :bom_process_id="lot_process.id"
              :lot_type_id="target_lot.lot_type_id"
              :package_process_yn="
                findInfoFromId(process, lot_process.process_id)
                  .package_process_yn
              "
              :product_group_yn="
                findInfoFromId(products, target_lot.product_id).product_group_yn
              "
              @oninsert="insertNewSeq($event, lot_process.process_id)"
              @ondelete="
                target_lot.lot_type_id ==
                lot_types.find(x => x.detail == 'done process').id
                  ? checkAndDeleteProduct($event)
                  : checkValidDeleteSeq($event)
              "
              @ondeletePackage="checkAndDeleteProduct($event)"
            ></component>
          </div>
          <!-- Add Arrows -->
        </div>
        <div class="slide_btn_wrap">
          <button type="button" class="button-prev">
            <i class="fa fa-angle-left"></i>
          </button>
          <button type="button" class="button-next">
            <i class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
    <lot-search
      v-if="showLotSearch"
      @onclose="hideLotSearch()"
      @onselect="selectLot($event)"
    ></lot-search>
    <resource-return
      v-if="showResourceReturn"
      @onClose="showResourceReturn = false"
      @onSelect="
        $event => {
          returnResource($event);
          showResourceReturn = false;
        }
      "
      :target_lot="target_lot"
      :remain_materials="
        target_lot.custom_input_yn ? getNotUsedResourceList : null
      "
    ></resource-return>
    <div v-if="showResourceReturn" class="modal_layer"></div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
// import ProductionProcessCard from '@/layouts/components/ProductionProcessCard.vue';
import ResourceReturn from '@/layouts/components/ResourceReturn';

import LotSearch from '@/layouts/components/search-popup/LotSearch';
import { mapGetters } from 'vuex';
import FavoriteMixin from '@/mixins/favorite';
import fetchMixin from '@/mixins/fetch';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import routes from '@/routes/routes';

Swiper.use([Navigation, Pagination]);

export default {
  mixins: [fetchMixin, SpinnerMixin, ModalMixin, FavoriteMixin],
  components: {
    // ProductionProcessCard,
    LotSearch,
    ResourceReturn,
  },
  data() {
    return {
      swiper: null,
      count: 0,
      screenWidth: this.$screen.width,
      showResourceReturn: false,
    };
  },
  computed: {
    ...mapGetters({
      showLotSearch: 'getShowLotSearchFromProductionPage',
      boms: 'getBom',
      process: 'getProcess',
      materials: 'getMaterial',
      products: 'getProduct',
      companys: 'getCompany',
      lot_types: 'getLotType',
      target_lot: 'getTargetLotFromProduction',
      unit_conversion: 'getUnitConversion',
      units: 'getUnitCodes',
      lots: 'getLot',
      keyCountForReDraw: 'getKeyCountForReDrawFromProduction',
      materialGroups: 'getMaterialGroup',
      machines: 'getMachines',
      product_occupied_stock: 'getProductOccupiedStock',
    }),
    dynamicComponent() {
      if (Number(localStorage.getItem('saupKey')) == 21)
        return () =>
          import(
            '@/views/forms/Custom/Process/21/CustomProductionProcessCard.vue'
          );
      else if (Number(localStorage.getItem('saupKey')) == 23) {
        return () =>
          import('@/layouts/components/23/SanchaeTownProductionProcessCard');
      } else
        return () => import('@/layouts/components/ProductionProcessCard.vue');
    },
    getNotUsedResourceList() {
      let production_infos = this.lodash
        .clonedeep(this.target_lot.bom_info.bom_process)
        .map(x => {
          const workOrderQuantity = this.target_lot.quantity;

          const usedQuantity =
            x.production_info != null
              ? this.$decimalAdd(
                  x.production_info.pass_quantity,
                  x.production_info.fail_quantity,
                )
              : 0;
          const notProcessedQuantity = this.$decimalSub(
            workOrderQuantity,
            usedQuantity,
          );
          return x.bom_resource.map(y => {
            const bomResourceQuantity = y.quantity;
            let remainQuantity = 0;

            if (y.percent_yn) {
              let resourceInfo = null;
              let cvr_ratio = null;
              const productInfo = this.findInfoFromId(
                this.products,
                this.target_lot.product_id,
              );
              if ([1, 2, 3].includes(y.resource_type_id)) {
                resourceInfo = this.findInfoFromId(
                  this.materials,
                  y.material_id,
                );
                cvr_ratio = this.unit_conversion.find(
                  x =>
                    x.in_unit_id == resourceInfo.incoming_unit_id &&
                    x.out_unit_id == productInfo.stock_unit_id,
                );
              } else {
                resourceInfo = this.findInfoFromId(this.products, y.product_id);
                cvr_ratio = this.unit_conversion.find(
                  x =>
                    x.in_unit_id == resourceInfo.stock_unit_id &&
                    x.out_unit_id == productInfo.stock_unit_id,
                );
              }
              if (cvr_ratio == undefined) {
                cvr_ratio = 1;
              } else {
                cvr_ratio = cvr_ratio.cvr_ratio;
              }

              remainQuantity = this.$decimalMul(
                this.$makeNumber(notProcessedQuantity),
                this.$decimalMul(this.$decimalDiv(y.quantity, 100), cvr_ratio),
              );

              remainQuantity = this.$decimalDiv(
                remainQuantity,
                this.getCvrRatio(
                  resourceInfo.incoming_unit_id,
                  resourceInfo.using_unit_id,
                ),
              );
              return {
                cvr: this.getCvrRatio(
                  resourceInfo.incoming_unit_id,
                  resourceInfo.using_unit_id,
                ),
                remain_quantity: remainQuantity,
                material_id: y.material_id,
              };
            } else {
              remainQuantity = this.$decimalMul(
                notProcessedQuantity,
                bomResourceQuantity,
              );
            }

            return {
              material_id: y.material_id,
              remain_quantity: remainQuantity,
            };
          });
        });

      production_infos = production_infos
        .reduce((a, b) => a.concat(b))
        .filter(x => x.remain_quantity > 0 && x.material_id != null);

      const obj = this.$groupArrayObject(production_infos, 'material_id');
      return obj.map(x => {
        return {
          material_id: Number(x.group_key),
          remain_quantity: x.element
            .map(y => y.remain_quantity)
            .reduce((a, b) => this.$decimalAdd(a, b), 0),
        };
      });
    },
    validChk() {
      if (this.target_lot != null) {
        let nullChk = 0;

        this.target_lot.bom_info.bom_process.forEach(el => {
          if (el.production_info == null) nullChk++;
        });

        if (this.target_lot.bom_info.bom_process.length == nullChk) {
          return false;
        } else {
          return true;
        }
      } else return false;
    },
  },
  methods: {
    async returnResource(arg) {
      console.log(arg);
      const reCalReturnQuantity = arg.map(x => {
        if (x.material_id != null) {
          const target_material = this.materials.find(
            y => y.id == x.material_id,
          );
          const cvr_ratio = this.unit_conversion.find(
            x =>
              x.in_unit_id == target_material.incoming_unit_id &&
              x.out_unit_id == target_material.using_unit_id,
          );
          if (cvr_ratio != undefined) {
            x.return_quantity = this.$decimalMul(
              this.$makeNumber(x.return_quantity),
              cvr_ratio.cvr_ratio,
            );
          } else {
            x.return_quantity = this.$makeNumber(x.return_quantity);
          }
          return x;
        } else {
          x.return_quantity = this.$makeNumber(x.return_quantity);
          return x;
        }
      });
      await this.UPDATE_WITH_PAYLOAD(
        'UPDATE_LOT_RESOURCE_RETURN',
        { data_list: reCalReturnQuantity },
        '반환',
      );
    },
    checkProcessVerification(arg, arg2, process_id) {
      const process = this.findInfoFromId(this.process, process_id);
      if (
        Object.keys(process).includes('process_verification_id') &&
        process.process_verification_id != null
      ) {
        const seq = arg.seq_list[arg.seq_list.length - 1];
        const payload = {
          check_quantity: seq.quantity,
          create_time: seq.create_time,
          id: seq.id,
          input_date: seq.create_time.substr(0, 10),
          lot_process_id: seq.lot_process_id,
          lot_seq_id: seq.id,
          lot_id: this.target_lot.id,
          pass_quantity: seq.pass_quantity,
          process_id: process_id,
          saup_no: localStorage.getItem('userSaup'),
          seq_fail_quantity: seq.fail_quantity,
          seq_number: arg.seq_list.length,
          seq_pass_quantity: seq.pass_quantity,
          seq_quality_id: null,
          unpass_quantity: '0',
          verification_state: 'N',
          seq_defect_list: [
            {
              check_quantity: seq.quantity,
              id: null,
              image: null,
              lot_seq_id: seq.id,
              pass_quantity: seq.pass_quantity,
              pass_yn: true,
              unpass_quantity: seq.fail_quantity,
              verification_id: process.process_verification_id,
            },
          ],
        };
        console.log(payload);
        this.$store
          .dispatch('INSERT_SEQ_QUALITY', payload)
          .then(response => {
            console.log(response);
            this.FETCH_WITH_PAYLOAD_NO_SPINNER(
              'FETCH_SEQ_DEFECT_PRODUCTION',
              payload.id,
              '공정불량',
            );
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('등록 실패', '등록 중 오류발생');
          })
          .finally(() => {});
      } else if (process.machine_id != null) {
        const machine = this.findInfoFromId(this.machines, process.machine_id);
        if (machine != undefined && machine.process_verification_id != null) {
          const seq = arg.seq_list[arg.seq_list.length - 1];
          const payload = {
            check_quantity: seq.quantity,
            create_time: seq.create_time,
            id: seq.id,
            input_date: seq.create_time.substr(0, 10),
            lot_process_id: seq.lot_process_id,
            lot_seq_id: seq.id,
            lot_id: this.target_lot.id,
            pass_quantity: seq.pass_quantity,
            process_id: process_id,
            saup_no: localStorage.getItem('userSaup'),
            seq_fail_quantity: seq.fail_quantity,
            seq_number: arg.seq_list.length,
            seq_pass_quantity: seq.pass_quantity,
            seq_quality_id: null,
            unpass_quantity: '0',
            verification_state: 'N',
            seq_defect_list: [
              {
                check_quantity: seq.quantity,
                id: null,
                image: null,
                lot_seq_id: seq.id,
                pass_quantity: seq.pass_quantity,
                pass_yn: true,
                unpass_quantity: seq.fail_quantity,
                verification_id: machine.process_verification_id,
              },
            ],
          };
          console.log(payload);
          this.$store
            .dispatch('INSERT_SEQ_QUALITY', payload)
            .then(response => {
              console.log(response);
              this.FETCH_WITH_PAYLOAD(
                'FETCH_SEQ_DEFECT_PRODUCTION',
                payload.id,
                '공정불량',
              );
            })
            .catch(error => {
              console.log(error);
              this.openOneButtonModal('등록 실패', '등록 중 오류발생');
            })
            .finally(() => {});
        }
      }
    },
    calNeedQuantity(resource, resource_type, pass_quantity) {
      // console.log('resource.percent_yn', resource);
      if (resource.percent_yn) {
        let resourceInfo = null;
        let cvr_ratio = null;
        const productInfo = this.findInfoFromId(
          this.products,
          this.target_lot.product_id,
        );
        if (resource_type == 1) {
          resourceInfo = this.findInfoFromId(
            this.materials,
            resource.material_id,
          );
          cvr_ratio = this.unit_conversion.find(
            x =>
              x.in_unit_id == resourceInfo.incoming_unit_id &&
              x.out_unit_id == productInfo.stock_unit_id,
          );
        } else {
          resourceInfo = this.findInfoFromId(
            this.products,
            resource.product_id,
          );
          cvr_ratio = this.unit_conversion.find(
            x =>
              x.in_unit_id == resourceInfo.stock_unit_id &&
              x.out_unit_id == productInfo.stock_unit_id,
          );
        }
        if (cvr_ratio == undefined) {
          cvr_ratio = 1;
        } else {
          cvr_ratio = cvr_ratio.cvr_ratio;
        }

        console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
        console.log('resourceInfo', resourceInfo.id);
        console.log('using_unit_id = ', resourceInfo.using_unit_id);
        console.log('incoming_unit_id = ', resourceInfo.incoming_unit_id);
        console.log('target_unit_id = ', productInfo.stock_unit_id);
        console.log('resource.quantity ', resource.quantity);
        console.log('pass_quantity ', pass_quantity);
        console.log('cvr_ratio', cvr_ratio);

        return this.$decimalMul(
          this.$makeNumber(pass_quantity),
          this.$decimalMul(this.$decimalDiv(resource.quantity, 100), cvr_ratio),
        );
      } else {
        return this.$makeNumber(
          this.$decimalMul(
            this.$makeNumber(resource.quantity),
            this.$makeNumber(pass_quantity),
          ),
        );
      }
    },
    async doneProcess() {
      this.showSpinner();
      await this.$store
        .dispatch('UPDATE_LOT_INFO_DONE', this.target_lot)
        .then(async () => {
          let max_index = 0;
          this.target_lot.bom_info.bom_process.forEach(el => {
            if (el.index > max_index) max_index = el.index;
          });
          // console.log('max_index', max_index);
          let min_pass_quantity = 99999999999999;
          const filterLastIndexProcess = this.lodash.clonedeep(
            this.target_lot.bom_info.bom_process.filter(
              x => x.index == max_index,
            ),
          );
          filterLastIndexProcess.forEach(el => {
            if (el.production_info == null) min_pass_quantity = 0;
            else if (el.production_info.pass_quantity < min_pass_quantity)
              min_pass_quantity = el.production_info.pass_quantity;
          });

          // console.log('min_quantity', min_pass_quantity);
          const target_product = this.findInfoFromId(
            this.products,
            this.target_lot.product_id,
          );
          // console.log('target_product', target_product);
          const checkPackageProcess = this.target_lot.bom_info.bom_process.map(
            x =>
              this.findInfoFromId(this.process, x.process_id)
                .package_process_yn,
          );

          if (min_pass_quantity != 0 && !checkPackageProcess.includes(true)) {
            this.showSpinner();
            await this.$store
              .dispatch('INSERT_PRODUCT_CONTROL_ALL', {
                product_ident: this.target_lot.lot_number,
                quantity: min_pass_quantity,
                product_id: this.target_lot.product_id,
                unit_id: target_product.stock_unit_id,
                store_id:
                  target_product.default_store_id != undefined
                    ? target_product.default_store_id
                    : null,
                lot_id: this.target_lot.id,
              })
              .then(() => {
                this.openOneButtonModal(
                  '생산공정 완료',
                  '생산공정이 모두 처리되어 완료되었습니다.',
                );
                this.target_lot.lot_type_id = this.lot_types.find(
                  x => x.detail == 'done process',
                ).id;
                this.showResourceReturn = true;
              })
              .catch(() => {
                this.openOneButtonModal(
                  '공정 완료 실패',
                  '공정 완료처리 도중 오류가 발생하였습니다.',
                );
              });
          } else {
            this.openOneButtonModal(
              '생산공정 완료',
              '생산공정이 모두 처리되어 완료되었습니다.',
            );
            this.target_lot.lot_type_id = this.lot_types.find(
              x => x.detail == 'done process',
            ).id;
            this.showResourceReturn = true;
          }
        })
        .catch(() => {
          this.openOneButtonModal(
            '실패',
            '작업 완료 처리 중 오류가 발생하였습니다.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    goBomManagementPage(product_id) {
      const goingPage = routes[0].children.find(x => x.path == '/code/bom');
      if (goingPage != undefined) {
        goingPage.meta.select = product_id;
      }
      this.$router.push('/code/bom');
    },
    checkAndDeleteProduct(e) {
      const thisIndex = this.target_lot.bom_info.bom_process.find(
        x => x.id == e.bom_process_id,
      ).index;
      // console.log(thisIndex);
      const nextProcessList = this.target_lot.bom_info.bom_process.filter(
        x => x.index == thisIndex + 1,
      );
      if (nextProcessList.length <= 0) {
        this.showSpinner();
        this.$store
          .dispatch('FETCH_PRODUCT_CONTROL_FROM_LOT_ALL', this.target_lot.id)
          .then(response => {
            const productControlList = response.data.data_list;
            if (
              productControlList == undefined ||
              productControlList.length == 0
            ) {
              this.checkValidDeleteSeq(e);
              return;
            }
            let deleteControlList = productControlList.filter(
              x => x.lot_seq_id == e.seq.id,
            );
            if (
              productControlList.length > 0 &&
              productControlList.filter(x => x.lot_seq_id != null) < 1
            ) {
              deleteControlList = productControlList[0];
            }

            const filteredOutputControl = deleteControlList.filter(
              x => x.product_in_out_list.length > 1,
            );

            //  여기서 target_lot의  제품 생산 기록의 수량 전부 더한다음.***group product_id
            //  occupied stock 가져와서 제품 아이디로 find 해서 quantity 찾은다음.
            // 빼 ! 그래서 음수면 isOverOccupied 를 true로
            // 생산기록이 예를들어서 1(100),2(200),3(300)
            // occupied stock이 1(20), 2(300), 3(20)
            //occupied stock 를 카피해놓고 find 헤서 생산기록 수량의 합을 빼는 로직을
            // filter로 해서 length 체크 > 0 ? true : false
            const productInList = this.lodash.clonedeep(this.target_lot)
              .product_in;
            let occupiedStockList = this.lodash
              .clonedeep(this.product_occupied_stock)
              .filter(
                x =>
                  productInList.find(y => y.product_id == x.product_id) !=
                  undefined,
              );

            occupiedStockList = occupiedStockList.filter(
              x =>
                this.$decimalSub(
                  productInList
                    .filter(y => y.product_id == x.product_id)
                    .map(y => y.quantity)
                    .reduce((a, b) => this.$decimalAdd(a, b), 0),
                  x.quantity,
                ) < 0,
            );

            let isOverOccupied = occupiedStockList.length > 0 ? true : false;

            if (filteredOutputControl.length > 0) {
              this.openOneButtonModal(
                '삭제 불가',
                '생산된 제품의 출고 기록이 있으므로<br /> 삭제할 수 없습니다.',
              );
              return;
            } else if (isOverOccupied) {
              this.openOneButtonModal(
                '삭제 불가',
                '생산된 제품이 다른 작업에서 사용될 예정이므로 <br /> 삭제할 수 없습니다.',
              );
              return;
            } else {
              if (deleteControlList.length > 0) {
                this.$store
                  .dispatch(
                    'DELETE_PRODUCT_CONTROL_LIST',
                    deleteControlList.map(x => x.id),
                  )
                  .then(() => {
                    this.checkValidDeleteSeq(e);
                  })
                  .catch(error => {
                    console.log(error);
                    this.openOneButtonModal(
                      '삭제 중 오류',
                      '삭제 중 오류가 발생하였습니다.',
                    );
                  });
              } else {
                this.checkValidDeleteSeq(e);
              }
            }
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '삭제 중 오류',
              '삭제 중 오류가 발생하였습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        if (nextProcessList.find(x => x.production_info != null) != undefined) {
          this.openOneButtonModal(
            '취소불가',
            '다음공정의 작업내용이 있으므로 취소할 수 없습니다.',
          );
        } else {
          this.checkValidDeleteSeq(e);
        }
      }
    },
    checkValidDeleteSeq(e) {
      // console.log(e);
      const thisIndex = this.target_lot.bom_info.bom_process.find(
        x => x.id == e.bom_process_id,
      ).index;
      // console.log(thisIndex);
      const nextProcessList = this.target_lot.bom_info.bom_process.filter(
        x => x.index == thisIndex + 1,
      );
      if (nextProcessList.length > 0) {
        const hasProductionInfo = nextProcessList.find(
          x => x.production_info != null,
        );
        if (hasProductionInfo != undefined) {
          this.openOneButtonModal(
            '취소불가',
            '다음공정의 작업내용이 있으므로 취소할 수 없습니다.',
          );
        } else {
          const payload = {
            seq_id: e.seq.id,
            lot_id: this.target_lot.id,
            pass_quantity: e.seq.pass_quantity,
            fail_quantity: e.seq.fail_quantity,
          };
          this.deleteSeq(payload, e.bom_process_id);
        }
      } else {
        const payload = {
          seq_id: e.seq.id,
          lot_id: this.target_lot.id,
          pass_quantity: e.seq.pass_quantity,
          fail_quantity: e.seq.fail_quantity,
        };
        this.deleteSeq(payload, e.bom_process_id);
      }
    },
    async deleteSeq(payload, bom_process_id) {
      this.showSpinner();
      console.log('delete seq', payload);
      await this.$store
        .dispatch('UPDATE_LOT_PROCESS_SEQ', payload)
        .then(async response => {
          // console.log(response);
          const thisProcess = this.target_lot.bom_info.bom_process.find(
            x => x.id == bom_process_id,
          );

          let received_info = this.lodash.clonedeep(response.data.data);
          thisProcess.production_info = received_info;
          if (received_info != null && received_info.seq_list.length > 0) {
            received_info.seq_list = this.lodash.clonedeep(
              received_info.seq_list.sort((a, b) => a.id - b.id),
            );
          }
          this.$store.commit(
            'setKeyCountForReDrawToProduction',
            this.keyCountForReDraw + 1000,
          );

          // await this.FETCH('FETCH_LOT_ALL', '작업지시');

          this.target_lot.lot_number = this.findInfoFromId(
            this.lots,
            this.target_lot.id,
          ).lot_number;
          this.target_lot.lot_type_id = this.findInfoFromId(
            this.lots,
            this.target_lot.id,
          ).lot_type_id;
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '취소 실패',
            '승인 취소 중 오류가 발생하였습니다.',
          );
        })
        .finally(async () => {
          this.showSpinner();
          setTimeout(async () => {
            await this.FETCH_NO_SPINNER(
              'FETCH_PRODUCT_OCCUPIED_STOCK',
              '제품 사용중 재고',
            );
            await this.FETCH_LOT_PRODUCTION(this.target_lot.id);
            this.hideSpinner();
          }, 1000);
        });
    },
    insertNewSeq(e, process_id) {
      console.log('e!!!!!!!!', e);
      e.seq.pass_quantity = this.$makeNumber(e.seq.pass_quantity);
      e.seq.fail_quantity = this.$makeNumber(e.seq.fail_quantity);
      // const failQuantity =
      //   this.$makeNumer(e.seq.pass_quantity) -
      //   this.$makeNumber(e.seq.fail_quantity);
      if (
        this.$makeNumber(e.seq.quantity) <
        this.$decimalAdd(
          this.$makeNumber(e.seq.pass_quantity),
          this.$makeNumber(e.seq.fail_quantity),
        )
      ) {
        this.openOneButtonModal(
          '등록불가',
          '통과수량과 불량수량의 합은<br /> 진행수량을 넘길 수 없습니다. ',
        );
        return;
      }
      // } else if (failQuantity == true) {
      //   this.$$makeNumber(e.seq.fail_quantity);
      // }

      this.showSpinner();
      this.$store
        .dispatch('INSERT_LOT_PROCESS_SEQ', e)
        .then(async response => {
          // console.log(response);
          const seq_return = this.lodash.clonedeep(response.data.data);
          const this_seq = this.lodash
            .clonedeep(seq_return)
            .seq_list.sort((a, b) => b.id - a.id)[0];
          const seq_payload = this.lodash.clonedeep(e);

          const thisProcess = this.target_lot.bom_info.bom_process.find(
            x => x.id == e.bom_process_id,
          );
          let received_info = this.lodash.clonedeep(response.data.data);
          thisProcess.production_info = received_info;
          if (received_info != null && received_info.seq_list.length > 0) {
            received_info.seq_list = this.lodash.clonedeep(
              received_info.seq_list.sort((a, b) => a.id - b.id),
            );
          }
          this.$store.commit(
            'setKeyCountForReDrawToProduction',
            this.keyCountForReDraw + 1000,
          );

          this.target_lot.lot_number = this.findInfoFromId(
            this.lots,
            e.lot_id,
          ).lot_number;
          this.target_lot.lot_type_id = this.findInfoFromId(
            this.lots,
            e.lot_id,
          ).lot_type_id;

          const bom_process = this.target_lot.bom_info.bom_process.find(
            x => x.id == e.bom_process_id,
          );
          let use_list = [];
          let bomProcessTemp = this.lodash.clonedeep(
            this.target_lot.bom_info.bom_process,
          );
          console.log('bomProcessTemp', bomProcessTemp);
          let bomResourceTemp = bomProcessTemp.map(x => x.bom_resource);
          bomResourceTemp = bomResourceTemp.reduce((a, b) => a.concat(b));

          //자동투입인지 수동투입인지 확인

          if (this.target_lot.custom_input_yn) {
            console.log('수동투입===================================');
            //수동투입
            const custom_expect_list = this.lodash.clonedeep(
              this.target_lot.lot_resource_expect_list,
            );
            if (
              custom_expect_list
                .map(z => z.quantity)
                .reduce((a, b) => this.$decimalAdd(a, b), 0) != 0
            ) {
              const mapCustomExpectList = custom_expect_list.map(z => {
                const target_resource =
                  z.material_id != null
                    ? this.findInfoFromId(this.materials, z.material_id)
                    : this.findInfoFromId(this.products, z.product_id);
                z.lot_id = this.target_lot.id;
                z.expect_id = z.id;
                z.expect_quantity = 0;
                z.resource_type_id = target_resource.resource_type_id;
                z.product_item_id = z.product_id;
                z.box_unit_id = null;
                z.standard = target_resource.standard;
                z.private_usage = this.$makeNumber(z.quantity);
                z.quantity = this.$makeNumber(z.quantity);
                z.unit_id =
                  z.product_id != null
                    ? target_resource.stock_unit_id
                    : target_resource.using_unit_id;
                z.lot_seq_id = thisProcess.production_info.seq_list.sort(
                  (a, b) => b.id - a.id,
                )[0].id;
                let findFromBomResource = bomResourceTemp.find(
                  x =>
                    x.material_id == z.material_id &&
                    x.product_id == z.product_id,
                );
                if (findFromBomResource == undefined) {
                  let groupMaterials = this.materialGroups.filter(
                    h => h.member_material_id == z.material_id,
                  );
                  if (groupMaterials.length > 0) {
                    groupMaterials = groupMaterials.map(
                      h => h.group_material_id,
                    );
                    findFromBomResource = bomResourceTemp.find(h =>
                      groupMaterials.includes(h.material_id),
                    );
                    if (findFromBomResource == undefined) {
                      z.bom_resource_id = 1;
                    } else {
                      z.bom_resource_id = findFromBomResource.id;
                    }
                  } else {
                    z.bom_resource_id = 1;
                  }
                } else {
                  z.bom_resource_id = findFromBomResource.id;
                }

                return z;
              });
              use_list = mapCustomExpectList;
            }
          } else {
            console.log('자동투입===================================');
            //자동투입
            bom_process.bom_resource.forEach(el => {
              const resource =
                el.product_id != null
                  ? this.findInfoFromId(this.products, el.product_id)
                  : this.findInfoFromId(this.materials, el.material_id);

              //그룹자재인지 확인
              if (resource.material_group_yn) {
                console.log('그룹자재===================================');
                //그룹 자재임
                const mapResource = this.target_lot.lot_resource_expect_list.map(
                  x => x.material_id,
                );
                let mapGroupMaterials = this.materialGroups
                  .filter(x => mapResource.includes(x.member_material_id))
                  .map(x => x.member_material_id);

                const listTemp = [];
                mapGroupMaterials.forEach(element => {
                  const insertTemp = this.lodash.clonedeep(el);
                  insertTemp.material_id = element;
                  listTemp.push(insertTemp);
                });

                listTemp.forEach(elel => {
                  const expect = this.lodash
                    .clonedeep(this.target_lot)
                    .lot_resource_expect_list.find(
                      x => x.material_id == elel.material_id,
                    );
                  // console.log('@@@@');
                  if (expect == undefined) return;
                  if (expect.quantity > 0) {
                    const append_item = {
                      lot_id: this.target_lot.id,
                      expect_id: expect.id,
                      expect_quantity: expect.quantity,
                      resource_type_id: elel.resource_type_id,
                      product_id: null,
                      material_id: elel.material_id,
                      product_item_id: null,
                      box_unit_id: null,
                      standard: resource.standard,
                      private_usage: Number(el.quantity),
                      quantity: expect.quantity,
                      unit_id: resource.using_unit_id,
                      lot_seq_id: thisProcess.production_info.seq_list.sort(
                        (a, b) => b.id - a.id,
                      )[0].id,
                      bom_resource_id: el.id,
                    };

                    append_item.expect_quantity = this.$decimalSub(
                      this.$makeNumber(append_item.expect_quantity),
                      this.$makeNumber(append_item.quantity),
                    );
                    expect.quantity = append_item.expect_quantity;
                    use_list.push(append_item);
                  }
                });
              } else {
                console.log(
                  '일반자재 or 제품===================================',
                );
                //자재 또는 제품임
                const expect = this.lodash
                  .clonedeep(this.target_lot)
                  .lot_resource_expect_list.find(
                    x =>
                      x.material_id == el.material_id &&
                      x.product_id == x.product_id,
                  );
                // console.log('@@@@');
                if (expect == undefined) return;
                const append_item = {
                  lot_id: this.target_lot.id,
                  expect_id: expect.id,
                  expect_quantity: expect.quantity,
                  resource_type_id: el.resource_type_id,
                  product_id: el.product_id,
                  material_id: el.material_id,
                  product_item_id: el.product_id,
                  box_unit_id: null,
                  standard: resource.standard,
                  private_usage: Number(el.quantity),
                  quantity:
                    el.product_id != null
                      ? this.$makeNumber(
                          this.calNeedQuantity(
                            el,
                            2,
                            this.$decimalAdd(
                              Number(e.seq.pass_quantity),
                              Number(e.seq.fail_quantity),
                            ),
                          ),
                        )
                      : this.$makeNumber(
                          this.calNeedQuantity(
                            el,
                            1,
                            this.$decimalAdd(
                              Number(e.seq.pass_quantity),
                              Number(e.seq.fail_quantity),
                            ),
                          ),
                        ),

                  unit_id:
                    el.product_id != null
                      ? resource.stock_unit_id
                      : resource.using_unit_id,
                  lot_seq_id: thisProcess.production_info.seq_list.sort(
                    (a, b) => b.id - a.id,
                  )[0].id,
                  bom_resource_id: el.id,
                };

                // append_item.expect_quantity = Number(
                //   (append_item.expect_quantity - append_item.quantity).toFixed(6),
                // );
                // expect.quantity = append_item.expect_quantity;
                console.log(
                  'append_item.expect_quantity',
                  append_item.expect_quantity,
                );
                console.log('append_item.quantity', append_item.quantity);
                if (
                  append_item.material_id != undefined &&
                  append_item.material_id != null
                ) {
                  const material = this.findInfoFromId(
                    this.materials,
                    append_item.material_id,
                  );
                  if (material != undefined) {
                    const cvr_ratio = this.getCvrRatio(
                      material.incoming_unit_id,
                      material.using_unit_id,
                    );
                    if (
                      cvr_ratio != undefined &&
                      cvr_ratio != null &&
                      !el.percent_yn
                    ) {
                      append_item.expect_quantity = this.$decimalSub(
                        this.$makeNumber(append_item.expect_quantity),
                        this.$decimalMul(
                          this.$makeNumber(append_item.quantity),
                          cvr_ratio,
                        ),
                      );
                      append_item.quantity = this.$decimalMul(
                        this.$makeNumber(append_item.quantity),
                        cvr_ratio,
                      );
                    } else {
                      append_item.expect_quantity = this.$decimalSub(
                        this.$makeNumber(append_item.expect_quantity),
                        this.$makeNumber(append_item.quantity),
                      );
                    }
                  } else {
                    append_item.expect_quantity = this.$decimalSub(
                      this.$makeNumber(append_item.expect_quantity),
                      this.$makeNumber(append_item.quantity),
                    );
                  }
                } else {
                  append_item.expect_quantity = this.$decimalSub(
                    this.$makeNumber(append_item.expect_quantity),
                    this.$makeNumber(append_item.quantity),
                  );
                }

                expect.quantity = append_item.expect_quantity;
                console.log('append_item', append_item);
                use_list.push(append_item);
              }
            });
          }

          // 중복 입력 필터링..
          let use_list_duplicate_removed = [];
          use_list.forEach(item => {
            if (use_list_duplicate_removed.length > 0) {
              if (
                !use_list_duplicate_removed
                  .map(x => x.expect_id)
                  .includes(item.expect_id)
              ) {
                use_list_duplicate_removed.push(item);
              }
            } else {
              use_list_duplicate_removed.push(item);
            }
          });

          use_list = use_list_duplicate_removed;

          console.log('@@@@@@@@@@ㅁㄴㅇㅁㄴㅇ', use_list);

          this.showSpinner();
          this.$store
            .dispatch('INSERT_LOT_RESOURCE_LIST', { data_list: use_list })
            .then(async response => {
              console.log(response);

              const notCompletedProcess = this.target_lot.bom_info.bom_process.find(
                el =>
                  el.production_info == null ||
                  this.$decimalSub(
                    this.getWaitQuantity(el),
                    this.$decimalAdd(
                      this.$makeNumber(el.production_info.pass_quantity),
                      this.$makeNumber(el.production_info.fail_quantity),
                    ),
                  ) != 0,
              );
              // console.log('notCompletedProcess', notCompletedProcess);
              this.checkProcessVerification(
                seq_return,
                seq_payload,
                process_id,
              );
              if (
                notCompletedProcess == undefined &&
                e.package_products == undefined
              ) {
                let max_index = 0;
                this.target_lot.bom_info.bom_process.forEach(el => {
                  if (el.index > max_index) max_index = el.index;
                });
                // console.log('max_index', max_index);
                let min_pass_quantity = 99999999999999;
                const filterLastIndexProcess = this.lodash.clonedeep(
                  this.target_lot.bom_info.bom_process.filter(
                    x => x.index == max_index,
                  ),
                );
                filterLastIndexProcess.forEach(el => {
                  if (el.production_info == null) min_pass_quantity = 0;
                  else if (el.production_info.pass_quantity < min_pass_quantity)
                    min_pass_quantity = el.production_info.pass_quantity;
                });

                // console.log('min_quantity', min_pass_quantity);
                const target_product = this.findInfoFromId(
                  this.products,
                  this.target_lot.product_id,
                );
                if (min_pass_quantity != 0) {
                  this.showSpinner();
                  await this.$store
                    .dispatch('INSERT_PRODUCT_CONTROL_ALL', {
                      product_ident: this.target_lot.lot_number,
                      quantity: min_pass_quantity,
                      product_id: this.target_lot.product_id,
                      unit_id: target_product.stock_unit_id,
                      store_id:
                        target_product.default_store_id != undefined
                          ? target_product.default_store_id
                          : null,
                      lot_id: this.target_lot.id,
                      lot_seq_id: this_seq.id,
                    })
                    .then(() => {
                      this.openOneButtonModal(
                        '생산공정 완료',
                        '생산공정이 모두 처리되어 완료되었습니다.',
                      );
                      this.target_lot.lot_type_id = this.lot_types.find(
                        x => x.detail == 'done process',
                      ).id;
                      this.showResourceReturn = true;
                    })
                    .catch(() => {
                      this.openOneButtonModal(
                        '공정 완료 실패',
                        '공정 완료처리 도중 오류가 발생하였습니다.',
                      );
                    });
                } else {
                  this.showSpinner();
                  await this.$store
                    .dispatch('UPDATE_LOT_INFO_DONE', this.target_lot)
                    .then(() => {
                      this.openOneButtonModal(
                        '생산공정 완료',
                        '생산공정이 모두 처리되어 완료되었습니다.',
                      );
                      this.target_lot.lot_type_id = this.lot_types.find(
                        x => x.detail == 'done process',
                      ).id;
                      this.showResourceReturn = true;
                    })
                    .catch(() => {
                      this.openOneButtonModal(
                        '공정 완료 실패',
                        '공정 완료처리 도중 오류가 발생하였습니다.',
                      );
                    });
                }
              } else {
                if (e.package_products != undefined) {
                  const packPro = e.package_products;
                  const data_list = packPro.map(x => {
                    const package_product = this.findInfoFromId(
                      this.products,
                      x.member_product_id,
                    );
                    return {
                      product_ident: this.target_lot.lot_number,
                      quantity: x.pass_quantity,
                      product_id: x.member_product_id,
                      unit_id: package_product.stock_unit_id,
                      store_id:
                        package_product.default_store_id != undefined
                          ? package_product.default_store_id
                          : null,
                      lot_id: this.target_lot.id,
                      lot_seq_id: this_seq.id,
                    };
                  });
                  await this.$store
                    .dispatch('INSERT_PRODUCT_CONTROL_ALL_LIST', {
                      data_list: data_list,
                    })
                    .then(async () => {
                      if (notCompletedProcess == undefined) {
                        this.showSpinner();
                        await this.$store
                          .dispatch('UPDATE_LOT_INFO_DONE', this.target_lot)
                          .then(() => {
                            this.openOneButtonModal(
                              '생산공정 완료',
                              '생산공정이 모두 처리되어 완료되었습니다.',
                            );
                            this.target_lot.lot_type_id = this.lot_types.find(
                              x => x.detail == 'done process',
                            ).id;
                            this.showResourceReturn = true;
                          })
                          .catch(() => {
                            this.openOneButtonModal(
                              '공정 완료 실패',
                              '공정 완료처리 도중 오류가 발생하였습니다.',
                            );
                          });
                      }
                    })
                    .catch(() => {
                      this.openOneButtonModal(
                        '포장제품 생산 실패',
                        '포장제품 생산 도중 오류가 발생하였습니다.',
                      );
                    });
                } else {
                  //미완료
                }
              }
            })
            .catch(error => {
              console.log(error);
              this.openOneButtonModal(
                '입력 오류',
                '처리 내역을 등록하는 중 오류가 발생하였습니다.',
              );
            });
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '입력 오류',
            '처리 내역을 등록하는 중 오류가 발생하였습니다.',
          );
        })
        .finally(async () => {
          this.showSpinner();
          setTimeout(async () => {
            await this.FETCH_NO_SPINNER(
              'FETCH_PRODUCT_OCCUPIED_STOCK',
              '제품 사용중 재고',
            );
            await this.FETCH_LOT_PRODUCTION(this.target_lot.id);
            this.hideSpinner();
          }, 1000);
        });
    },
    getCvrRatio(input, output) {
      console.log(input, '/', output);
      if (input == output) return 1;
      const hit = this.unit_conversion.find(
        x => x.in_unit_id == input && x.out_unit_id == output,
      );
      return hit != undefined ? hit.cvr_ratio : 1;
    },

    hideLotSearch() {
      this.$store.commit('setShowLotSearchToProductionPage', false);
    },
    getWaitQuantity(tar) {
      const before_process = this.target_lot.bom_info.bom_process.filter(
        x => x.index == tar.index - 1,
      );

      if (before_process.length < 1) {
        return this.target_lot.quantity;
      } else {
        let minQuantity = 9999999999;
        before_process.forEach(element => {
          if (element.production_info == null) {
            minQuantity = 0;
          } else {
            if (element.production_info.pass_quantity < minQuantity) {
              minQuantity = element.production_info.pass_quantity;
            }
          }
        });
        return minQuantity;
      }
    },
    selectLot(arg) {
      this.$store.commit('setTargetLotToProduction', null);
      this.FETCH_LOT_PRODUCTION(arg.id);
      this.hideLotSearch();
    },
    async FETCH_LOT_PROCESS() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_LOT_PROCESS', this.target_lot.id)
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('로드 실패', '삭제된 작업입니다.');
          this.$store.commit('setTargetLotToProduction', null);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async FETCH_LOT_PRODUCTION(lot_id) {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_LOT_PRODUCTION', lot_id)
        .then(response => {
          console.log('패치 라트 프로덕션 ', response.data);
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('로드 실패', '삭제된 작업입니다.');
          this.$store.commit('setTargetLotToProduction', null);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    trackingLotNum(lotNum) {
      const goingPage = routes[0].children.find(
        x => x.path == '/product/tracking',
      );
      if (goingPage != undefined) {
        goingPage.meta.select = Number(lotNum.substr(8, 5));
      }
      this.$router.push('/product/tracking');
    },
  },

  mounted() {
    let slidesPerView = 6;
    if (this.screenWidth < 1280) {
      slidesPerView = 5;
    }
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView,
      freeMode: true,
      resizeReInit: true,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.button-next',
        prevEl: '.button-prev',
      },
    });
  },

  async created() {
    if (this.boms.length < 1) {
      await this.FETCH('FETCH_BOM', 'BOM');
    }
    if (this.process.length < 1) {
      await this.FETCH('FETCH_PROCESS', '공정');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '자재');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.lot_types.length < 1) {
      await this.FETCH('FETCH_LOT_TYPE', '공정상태');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.lots.length < 1) {
      await this.FETCH('FETCH_LOT_ALL', '작업지시');
    }
    if (this.unit_conversion.length < 1) {
      await this.FETCH('FETCH_UNIT_CONVERSION', '단위변환식');
    }
    if (this.target_lot != null) {
      await this.FETCH_NO_SPINNER(
        'FETCH_PRODUCT_OCCUPIED_STOCK',
        '제품 사용중 재고',
      );
      await this.FETCH_LOT_PRODUCTION(this.target_lot.id);
    }
    if (this.materialGroups.length < 1) {
      await this.FETCH('FETCH_MATERIAL_GROUP', '자재 그룹');
    }
    if (this.machines.length < 1) {
      await this.FETCH('FETCH_MACHINE', '설비');
    }
    await this.FETCH('FETCH_PRODUCT_OCCUPIED_STOCK', '제품 사용중 재고');
  },
};
</script>

<style lang="scss" scoped></style>
